<script>
	import Vue from 'vue';
	import GlobalVue from '../helper/Global.vue'
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	import swal from 'sweetalert';

	export default {
		extends: BaseVue,
		data() {
			return {
				input: {},
			}
		},
		computed: {
			mrValidation() {
				return this.$root.config.mrValidation.forgot_password
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			$(document).ready(() => {
				AOS.init()
			})
			this.$root.topProgress.done()
		},
		methods: {
			onSubmit(e) {
				if(e&&e.btnLoading()) return;
				Gen.apirest('/forgot-password', this.input, (err, resp) => {
					if(e) e.btnUnloading()
					if (err) return this.$refs.info.show(err.responseJSON.message, "warning")
					swal(resp.message, "","success")
					this.$router.push({ name: "Login" })
				}, 'POST')
			},
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
		<section class="section notopmargin nobottommargin wall-bg">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-md-7 col-lg-5">
						<div class="box-el login-form">
							<div class="text-center mb-3">
								<h4>{{web.lbl_forgot_password_capital}}</h4>
								<span>{{web.lbl_recover_ur_email}}</span>
							</div>
							<VForm @resp="onSubmit">
								<VInfo ref="info"></VInfo>
								<div class="form-group">
									<input name="email" onkeydown="return emailKey(event)" type="email" v-model="input.email" class="form-control" :placeholder="web.lbl_email" v-bind="validation('email')">
									<LabelError name="email"></LabelError>
								</div>
								<div class="form-group text-center mb-0">
									<button type="submit" class="button button-red btn-loading">{{web.lbl_send}}</button>
								</div>
							</VForm>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>