<script>
import GlobalVue from '@helper/Global.vue';
import Gen from '@helper/Gen';
// import swal from 'sweetalert';

export default {
  extends:GlobalVue,
  mounted(){
    this.refreshMeta()
  },
  methods:{
    refreshMeta(){
      Gen.rest(this.$route.path, {metainfo:true},(err,resp)=>{
        if(err) return console.error(err)
        $("title").text(resp.ss_meta_title+resp.prefix)
        $("meta[property='og:image']").attr('content', resp.ss_meta_image)
        $("meta[property='og:description']").attr('content', resp.ss_meta_desc+resp.prefix)
        $("meta[property='og:title']").attr('content', resp.ss_meta_title+resp.prefix)
        $("meta[property='og:site_name']").attr('content', resp.ss_sitename+resp.prefix)
        $("meta[property='og:url']").attr('content',resp.ss_url)
        $("meta[name='description']").attr('content', resp.ss_meta_desc+resp.prefix)
        $("meta[name='keywords']").attr('content', resp.ss_meta_keyword+resp.prefix)
        $("meta[name='author']").attr('content', resp.ss_author+resp.prefix)
        $("link[rel='canonical']").attr('content', resp.ss_url)
        if(resp.ss_meta_image){
          $("meta[property='og:image']").attr('content',resp.ss_meta_image)          
          $("meta[name='twitter:image']").attr('content',resp.ss_meta_image)
        }
        $("link[rel='alternate']").attr('href',resp.ss_url)
        $("meta[name='twitter:title']").attr('content',resp.ss_meta_title+resp.prefix)
        $("meta[name='twitter:description']").attr('content',resp.ss_meta_desc+resp.prefix)
        $("meta[name='twitter:url']").attr('content',resp.ss_url)
        this.$root.meta = resp
      })
    },
  },
  watch:{
    $route(){
      this.refreshMeta()
    }
  }
}

</script>
